@import '../../../styles/propertySets.css';

.root {
}

.icon {
  display: none;

  @media (--viewportMedium) {
    display: block;
    margin-bottom: 36px;
  }
}

.heading {
  @apply --marketplaceModalTitleStyles;
}

.field {
  margin: 25px 0 0 0;

  @media (--viewportMedium) {
    margin: 22px 0 0 0;
  }
}

.error {
  color: var(--failColor);
  margin-top: 0;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.offerTip,
.respect {
  text-align: center;
  line-height: 20px;
  margin: 30px 0;
  color: var(--matterColorAnti);
  font-size: 8pt;
}

.formHeadingDivision {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.listingImage {
  object-fit: cover;
}

.formDetails {
  display: flex;
  flex-direction: column;
}

.listingAuthor,
.listingPrice,
.listingSize,
.listingTitle {
  line-height: 2;
  font-size: 8pt;
}

.listingPrice,
.listingAuthor {
  /* margin-top: 10px; */
  font-weight: var(--fontWeightSemiBold);
}

.listingAuthor {
  margin-top: 10px;
}

.customOffer {
  width: 40%;
}

.offerTip {
  text-align: center;
  line-height: 20px;
  margin: 15px 0;
  color: var(--matterColorAnti);
  font-size: 8pt;
}

.customOfferTextError {
  border-bottom-color: red;
}

.customOfferError {
  color: var(--failColor);
}