@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

.root {
  overflow: auto;
}

.pageRoot {
  display: flex;
  @media (--viewportLarge) {
    padding-bottom: 0;
  }
}

.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--failColor);
  margin: 24px;
}

.sectionImages {
}

.heroHeight {
  @media (--viewportMedium) {
    height: calc(0.41 * 100vw);
    max-height: 50vh;
  }
}

.imageWrapperForSectionImage {
  composes: heroHeight;
  background-color: var(--matterColorNegative); /* Loading BG color */

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;
}

.actionBar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: var(--matterColorNegative);
  background-color: var(--matterColor);
  z-index: 1; /* bring on top of mobile image */
  left: 0;
  right: 0;

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;

  border-radius: var(--borderRadius);
}

.actionBarForProductLayout {
  /* margin: 24px; */
  width: calc(100% - 48px);
  height: 50px;

  @media (--viewportMedium) {
    margin: 24px 0 24px 0;
    width: 100%;
  }

  @media (--viewportLarge) {
    /* margin: 0 0 24px 0; */
  }
}

.actionBarForHeroLayout {
  position: absolute;
  top: 13px;
  left: 13px;
  width: calc(100% - 26px);

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: initial;
}

.ownListingText {
  @apply --marketplaceH4FontStyles;
  margin-top: auto;
  margin-bottom: auto;
  color: var(--matterColorLight);
  /* margin: 14px 12px 11px 24px; */

  @media (--viewportMedium) {
    /* margin: 25px 12px 22px 24px; */
  }
}

.ownListingTextPendingApproval {
  color: var(--matterColorLight);
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
  font-size: 9pt;
}

.closedListingText {
  @apply --marketplaceH4FontStyles;
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%;

  @media (--viewportMedium) {
    margin: 25px 12px 22px 24px;
  }
}

.editListingLink {
  @apply --marketplaceH4FontStyles;
  flex-shrink: 0;
  color: var(--matterColorNegative);
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10px;
  font-size: 9pt;

  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--matterColorLight);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    /* padding: 25px 24px 22px 12px; */
  }

  color: var(--matterColorLight);
}

.editIcon {
 display: none;
}

.rootForImage {
  composes: heroHeight;
  width: 100%;
  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    object-fit: cover;
    border-radius: var(--borderRadius);

    &:hover {
      transform: scale(1.005);
      box-shadow: var(--boxShadowListingCard);
    }
  }
}

.viewPhotos {
  @apply --marketplaceTinyFontStyles;
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;
  margin: 0;
  padding: 8px 13px 6px 13px;

  /* Colors */
  background-color: var(--matterColorLight);

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);
  display: none;

  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.contentWrapperForHeroLayout {
  @media (--viewportMedium) {
    max-width: 1056px;
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    position: relative;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px;
    margin: 0 auto 117px;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

.mainColumnForProductLayout {
  max-width: 100%;
  margin: auto;
 
  
  @media (--viewportMedium) {
    margin-left: 0;
    /* padding-right: 75px; */
    margin-right: 130px;
  }
  
  @media (--viewportLarge) {
    /* contentContainer's full width - (orderColumn + paddings + margin) */
    padding-top: 10%;
    margin-right: 190px;
    flex-basis: 250px;
    flex-grow: 1;
    /* flex-grow: 1; */
  }
}

.orderColumnForProductLayout {
  max-width: 100%;
  margin: auto;
  padding: 20px;
  
  @media (--viewportMedium) {
    width: 100%;
    margin: 0;
    padding-left: 0;
    padding-right: 0;

  }
  
  @media (--viewportLarge) {
    padding-top: 10%;
    margin-left: 190px;
    flex-basis: 250px;
    flex-grow: 1;
  }
}

.contentWrapperForProductLayout {
  margin: 0;

  @media (--viewportMedium) {
    width: 100%;
    padding: 0 20px 0 20px;
    /* margin: auto; */
  }

  @media (--viewportLarge) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 40px 0 40px;
  }
}

.mainColumnForHeroLayout {
  flex-basis: 100%;
  margin-bottom: 23px;

  @media (--viewportMedium) {
    margin-top: 50px;
    margin-bottom: 51px;
    flex-shrink: 0;
  }

  @media (--viewportLarge) {
    margin-top: 50px;
    margin-bottom: 0px;
    /* contentContainer's full width - (orderColumn + two paddings + margin + border) */
    max-width: calc(100% - 433px);
    flex-basis: calc(100% - 433px);
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.orderColumnForHeroLayout {
  @media (--viewportMedium) {
  }
  @media (--viewportLarge) {
    display: block;
    margin-top: 79px;
    margin-left: 60px;
    border-left: 1px solid var(--matterColorNegative);
    padding-left: 60px;
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.orderPanel {
  @media (--viewportLarge) {
    position: sticky;
    top: 108px;
  }
}

.productOrderPanel {
  @media (--viewportLarge) {
    display: block;
    /* margin-top: 24px; */
    position: fixed;
    top: 30%;
    width: 350px;    
    right: 40px;
  }

  @media (--viewportXLarge) {
    top: 35%;
  }
}

.sectionAvatar {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -31px;

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Flex would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    position: absolute;
    top: -49px;
    margin-left: 0;
    margin-top: 0;
  }
}

.avatarMobile {
  display: flex;

  @media (--viewportMedium) {
    display: none; /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none; /* Hide the large avatar from the smaller screens */

  @media (--viewportMedium) {
    display: flex;
  }
}

.sectionHeading {
  margin-top: 22px;
  margin-bottom: 34px;

  @media (--viewportMedium) {
    display: flex;
    margin-bottom: 31px;
  }
}
.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  }
}

.desktopPriceValue {
  /* Font */
  @apply --marketplaceModalTitleStyles;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 1px;
  }
}

.desktopPerUnit {
  /* Font */
  @apply --marketplaceH5FontStyles;
  color: var(--matterColor);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.heading {
  margin-bottom: 33px;
  padding: 0 24px;

  @media (--viewportMedium) {
    margin-bottom: 0;
    padding: 0;
  }
}

.title {
  /* Font */
  @apply --marketplaceModalTitleStyles;
  color: var(--matterColor);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 2px;
  }
}

.author {
  width: 100%;
  @apply --marketplaceH5FontStyles;
  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--matterColor);
  }
}

.authorNameLink {
  color: var(--matterColor);
}

.contactWrapper {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.separator {
  margin: 0 6px;
}

.contactLink {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceH5FontStyles;
  margin: 0;
}

.sectionDescription {
  padding: 0 20px;
  margin-bottom: 0;
  margin-left: 0;

  @media (--viewportMedium) {
    padding: 1px;
    /* margin-bottom: 40px; */
    margin-left: 0;
  }

  @media (--viewportLarge) {
    position: fixed;
    top: 30%;
    width: 272px;
    margin-left: 20px;
  }

  @media (--viewportXLarge) {
    top: 35%;
  }
}

.sectionDescriptionMobile {
  padding: 0;
  margin-bottom: 30px;
  margin-top: 30px;

  @media (--viewportLarge) {
  display: none;
  }

}


.sectionTitle {
  /* Font for sections titles */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColor);
  font-weight: var(--fontWeightSemiBold);
  letter-spacing: -0.11px;
  font-size: 10pt;
  margin-bottom: 0;
  margin-top: 0;

  @media (--viewportMedium) {
    font-size: 11pt;
    margin-bottom: 0;

  }

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;


  }
}

.detailsTitle,
.descriptionTitle {
  margin-top: 0;
  margin-bottom: 12px;
  font-size: 11pt;
 
  @media (--viewportMedium) {
  
    margin-bottom: 0;
  }
  
  @media (--viewportLarge) {

    margin-bottom: 20px;
  }

}

.descriptionTitleMobile {
  margin-top: 0;
  margin-bottom: 12px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 8px;
  }
}

.description {
  display: none;

  @media (--viewportLarge) {
    display:block;
    padding: 1px 0 5px 0;
    margin-top: 0;
    margin-bottom: 0;
    font-weight: var(--fontWeightRegular);
    line-height: 20px;
  
    /* Preserve newlines, but collapse other whitespace */
    white-space: pre-line;
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
}


.descriptionMobile {
  padding: 1px 0 5px 0;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: var(--fontWeightRegular);
  line-height: 20px;

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
  }


}

.sectionDetails {
  padding: 0 24px;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 40px;
  }
}

.details {
  padding-bottom: 1px;
  margin: 0;
}

.detailsRow {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  padding: 1px 0 4px 0;
  line-height: 36px;
  list-style: none;
  font-weight: var(--fontWeightRegular);

  &:not(:last-child) {
    border-bottom: 1px solid var(--matterColorNegative);
  }

  @media (--viewportMedium) {
    padding: 7px 0 0px 0;
    line-height: 40px;
  }
}

.detailLabel {
  font-weight: var(--fontWeightSemiBold);
  letter-spacing: -0.09px;
}

.sectionFeatures {
  padding: 0 24px;
  margin-bottom: 32px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 52px;
  }
}

.featuresTitle {
  composes: sectionTitle;

  margin-top: 0;
  margin-bottom: 16px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 12px;
  }
}

.sectionMap {
  padding: 0 24px;
  margin-bottom: 35px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
  }
}

.locationTitle {
  composes: sectionTitle;

  margin-top: 0;
  margin-bottom: 20px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 24px;
  }
}

.sectionReviews {
  padding: 0 24px;
  margin-bottom: 5px;

  @media (--viewportMedium) {
    padding: 0;
  }
  @media (--viewportLarge) {
    padding-bottom: 5px;
    margin-bottom: 0;
  }
}

.reviewsTitle {
  composes: sectionTitle;

  margin: 52px 0 20px 0;

  @media (--viewportMedium) {
    margin: 53px 0 27px 0;
  }
  @media (--viewportLarge) {
    padding: 5px 0 3px 0;
    margin: 56px 0 26px 0;
  }
}

.sectionAuthor {
  position: relative;
  padding: 0 24px;
  margin-bottom: 5px;
  display: none;

  @media (--viewportMedium) {
    padding: 0;
  }
  @media (--viewportLarge) {
    margin-bottom: 3px;
  }
}

.aboutProviderTitle {
  composes: sectionTitle;

  margin: 54px 0 23px 0;

  @media (--viewportMedium) {
    margin: 53px 0 27px 0;
  }
  @media (--viewportLarge) {
    margin: 64px 0 24px 0;
    padding: 5px 0 3px 0;
  }
}

.enquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.enquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    /* min-height: 400px; */
    margin: auto;
  }
}

.enquirySubmitButtonWrapper {
  /* position: fixed; */
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: calc(100vh - 193px);
  width: 100%;

  /* Static map: dimensions are 640px */
  max-width: 640px;
  max-height: 640px;
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;

  @media (--viewportMedium) {
    height: 75vh;
  }

  @media (--viewportLarge) {
    height: 417px;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.productGallery {
  margin: 0;

  @media (--viewportMedium) {
    margin: 24px 0 0 0;
    padding-bottom: 5px;
  }
  @media (--viewportLarge) {
    margin: 0 0 0 0;
    
  }
}

.productMobileHeading {
  @media (--viewportLarge) {
    display: none;
  }
}

.productDesktopHeading {
  display: none;
  @media (--viewportLarge) {
    display: block;
  }
}

.productSideContent {
}

.productBookingPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 24px;

    /* The calendar needs 312px */
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

.galleryColumnForProductLayout {
  
  justify-content: center;
    display: flex;
    padding: 20px;
    margin-top: -30px;
    margin-bottom: -76px;
   
    @media (--viewportMedium) {
      padding: 20px;
      margin-top: -20px;
      margin-bottom: -76px;
  
  }

  @media (--viewportLarge) {
    flex-basis: 285px;    
    flex-grow: 1;
    margin: 0;
  }

  @media (--viewportXLarge) {
    flex-basis: 295px;
  }
}

.actionBarForProductLayout {
  position: fixed;
  top: 20%;
  margin: auto;
  height: 40px;

  @media (--viewportMedium) {
    width: calc(100% - 80px);
    padding: 0 40px;
  }

  @media (--viewportLarge) {
    width: calc(100% - 100px);
  }  
}

.authorName {
  composes: sectionTitle;
}

.listingLikeIconMobile {
  position: absolute;
  top: 70px;
  right: 20px;

  @media (--viewportLarge) {
    display: none;
  }
}

.listingLikeIcon {
  display: none;

  @media (--viewportLarge) {
    display: flex;
    position: absolute;
    top: 5px;
    right: 5px;
    width: 90%;
    justify-content: flex-end;
    margin-right: 0;
  }

  @media (--viewportXLarge) {
  }
}

.likeDesktop {
  position: fixed;
}

.heartIcon {
  color: transparent;
  float: right;

  &:hover {
    color: var(--matterColorDark);
  }
}

.heartIconLiked {
  color: var(--matterColorDark);
  float: right;
}

.gallery {
  position: sticky;
  max-width: 330px;
  min-width: 350px;

  @media (--viewportLarge){
    max-width: 600px;

  }
}

.itemWrapper,
.itemWrapperFullscreen {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .item {
  max-width: 100%;
  max-height: 150%;
  height: calc(100vh - 130px);
} */

.verifiedBarge {
  margin-bottom: 5px;
  padding-left: 3px;

  @media (--viewportMedium) {
    padding-left: 5px;
    margin-bottom: 8px;
    margin-top: 0;
  }
}